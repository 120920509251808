var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"search":_vm.search,"item-key":"id","footer-props":{
          'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("reports.pageTitle"))+" ("+_vm._s(_vm.reports.length)+") ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.broadcasts",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.broadcast ? item.broadcast.broadcastName : "")+" ")]}},{key:"item.datetime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTime(item.datetime))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","color":"brand","dark":""},on:{"click":function($event){return _vm.showDialog(item, true)}}},[_vm._v(" "+_vm._s(_vm.$t("Mute"))+" ")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"550"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"py-6",attrs:{"outlined":""}},[_vm._t("title",function(){return [_c('v-card-title',{staticClass:"h5 pt-0"},[_vm._v(" "+_vm._s((_vm.soft ? _vm.$t("Mute") : _vm.$t("Ban")) + (_vm.selectedReport ? " " + _vm.selectedReport.reportedUser.displayName : ""))+" ")])]}),_c('v-container',{staticClass:"px-6",attrs:{"fluid":""}},[_c('v-autocomplete',{attrs:{"items":_vm.broadcasts,"item-text":"broadcastName","item-value":"id","multiple":"","label":_vm.$t('bans.broadcastPlaceholder'),"chips":"","deletable-chips":"","solo":""},model:{value:(_vm.selectedBroadcastIds),callback:function ($$v) {_vm.selectedBroadcastIds=$$v},expression:"selectedBroadcastIds"}}),_c('v-select',{attrs:{"items":_vm.banDurations,"label":_vm.$t('bans.banDurationLabel'),"solo":""},model:{value:(_vm.selectedBanDuration),callback:function ($$v) {_vm.selectedBanDuration=$$v},expression:"selectedBanDuration"}})],1),_c('v-card-actions',{staticClass:"px-6 pb-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text",attrs:{"color":"brand","disabled":!_vm.selectedBanDuration || _vm.selectedBroadcastIds.length === 0},on:{"click":_vm.banUser}},[_vm._v(" "+_vm._s(_vm.soft ? _vm.$t("Mute") : _vm.$t("Ban"))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }