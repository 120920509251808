






















































































































import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { Ban, BanActionPayload } from "../Ban/store/types";
import { Report } from "./reports/types";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import { BAN_NAMESPACE } from "../Ban/store";
import { MINUTE_IN_SECONDS } from "@/helpers/consts";
import { Namespace } from "@/store/types";

@Component({})
export default class ScheduleManagement extends Vue {
  @Action(`${BAN_NAMESPACE}/addBan`)
  addBan!: (data: BanActionPayload) => Promise<Ban>;

  @Action(`${Namespace.Report}/getReportedUsers`)
  getReportedUsers!: () => Promise<void>;

  @Action(`${Namespace.Report}/deleteReportedUser`)
  deleteReportedUser!: (id: string) => Promise<void>;

  @Getter(`${Namespace.Report}/reports`)
  readonly reports!: Report[];

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  search = "";

  dialog = false;
  selectedBroadcastIds: string[] = [];
  selectedBanDuration: number | null = null;
  selectedReport: Report | null = null;
  soft = false;

  get headers() {
    return [
      { text: this.$t("Reported user"), value: "reportedUser.displayName" },
      { text: this.$t("Reason"), value: "type" },
      { text: this.$t("broadcasts"), value: "broadcasts" },
      { text: this.$t("Reported by"), value: "reporter.displayName" },
      { text: this.$t("Report date"), value: "datetime" },
      {
        text: this.$t("dataTable.actions"),
        value: "actions",
        sortable: false,
        align: "center",
        cellClass: "text-no-wrap",
      },
    ];
  }

  get banDurations() {
    return [
      {
        text: this.$tc("timeUnit.minutes", 30, { value: 30 }),
        value: 30,
      },
      {
        text: this.$tc("timeUnit.minutes", 60, { value: 60 }),
        value: 60,
      },
      {
        text: this.$tc("timeUnit.minutes", 120, { value: 120 }),
        value: 120,
      },
    ];
  }

  showDialog(report: Report, soft: boolean): void {
    this.selectedBroadcastIds = [];
    this.selectedBanDuration = 0;
    this.selectedReport = report;
    this.soft = soft;
    this.dialog = true;
  }

  async deleteItem(item: Report) {
    await this.deleteReportedUser(item.id);
  }

  async banUser() {
    if (this.selectedReport && this.selectedBanDuration) {
      await this.addBan({
        banDurationSeconds: this.selectedBanDuration * MINUTE_IN_SECONDS,
        userId: this.selectedReport.reportedUser.userId,
        messageRouter: {
          tenantWide: false,
          broadcastIds: this.selectedBroadcastIds,
          channelIds: [],
        },
        message: `${this.soft ? "Soft" : "Hard"} Ban with reason: ${
          this.selectedReport.reportedMessage
        }`,
        soft: this.soft,
      });
      await this.deleteItem(this.selectedReport);
      this.dialog = false;
    }
  }

  created() {
    this.getReportedUsers();
  }

  getDateTime(dateTime: string | null): string {
    if (dateTime) {
      return this.$d(new Date(dateTime), "DateTimeShort");
    }
    return "";
  }
}
