










import PageTemplate from "@/components/PageTemplate.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";
import ReportedUsers from "@/components/Reports/ReportedUsers.vue";

@Component({
  components: {
    PageTemplate,
    ReportedUsers,
  },
})
export default class Reports extends Vue {
  RouteName = RouteName;
}
